import React, { useState } from 'react';
import { View, Text, TextInput, Button, StyleSheet, ScrollView } from 'react-native';

const App = () => {
  const [complaintText, setComplaintText] = useState('');
  const [emotions, setEmotions] = useState({
    Angry: 0,
    Frustration: 0,
    Disappointment: 0,
    Helplessness: 0,
    Anxiety: 0,
  });
  const [recommendation, setRecommendation] = useState('');
  const [responseTexts, setResponseTexts] = useState([]);
  const [apiError, setApiError] = useState(null);

  const handleAnalysis = async () => {
    const yourAPIUrl = 'https://35.221.160.56:5001/analyze';
    const chatGPTUrl = 'https://api.openai.com/v1/chat/completions';
    const api_key = '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';

    // 清空先前的回應
    setResponseTexts([]);

    // 對您自己的 API 發送請求
    try {
      const responseYourAPI = await fetch(yourAPIUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text: complaintText }),
      });

      if (responseYourAPI.status === 200) {
        const resultYourAPI = await responseYourAPI.json();
        setEmotions({
          Angry: resultYourAPI.emotion_scores['1_Anger'],
          Frustration: resultYourAPI.emotion_scores['2_Frustration'],
          Disappointment: resultYourAPI.emotion_scores['3_Disappointment'],
          Helplessness: resultYourAPI.emotion_scores['4_Helplessness'],
          Anxiety: resultYourAPI.emotion_scores['5_Anxiety'],
        });
        setRecommendation(resultYourAPI.recovery_strategy);
      } else {
        setApiError(`Failed to connect to your API. Status code: ${responseYourAPI.status}`);
      }
    } catch (error) {
      setApiError(`Error in your API request: ${error}`);
    }

    // 對 ChatGPT API 發送請求
    try {
      const systemMessage = `Play the role of customer service and patiently reply to customer messages in English. ${recommendation}`;
      const responseChatGPT = await fetch(chatGPTUrl, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${api_key}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: systemMessage },
            { role: 'user', content: complaintText },
          ],
        }),
      });
    
      if (responseChatGPT.status === 200) {
        const resultChatGPT = await responseChatGPT.json();
        setResponseTexts([resultChatGPT.choices[0].message.content]);
      } else {
        setApiError(`Failed to connect to ChatGPT API. Status code: ${responseChatGPT.status}`);
      }
    } catch (error) {
      setApiError(`Error in ChatGPT API request: ${error}`);
    }
  };

  const clearFields = () => {
    setComplaintText('');
    setEmotions({
      Angry: 0,
      Frustration: 0,
      Disappointment: 0,
      Helplessness: 0,
      Anxiety: 0,
    });
    setRecommendation('');
    setResponseTexts([]);
    setApiError(null);
  };

  return (
    <ScrollView style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.headerText}>Paste Customers' Complaints Texts here</Text>
      </View>
      <TextInput
        style={styles.input}
        multiline
        placeholder="Type the complaint here..."
        value={complaintText}
        onChangeText={setComplaintText}
      />
      <View style={styles.buttonContainer}>
        <Button title="Calculation and Diagnose" onPress={handleAnalysis} />
        <Button title="Clear" onPress={clearFields} color="red" />
      </View>
      <View style={styles.emotionsHeader}>
        <Text style={styles.emotionsHeaderText}>Negative Emotions</Text>
      </View>
      <View style={styles.table}>
        {Object.entries(emotions).map(([emotion, score]) => (
          <View key={emotion} style={styles.tableRow}>
            <Text style={styles.tableCell}>{emotion}</Text>
            <Text style={styles.tableCell}>{score.toFixed(2)}</Text>
          </View>
        ))}
      </View>
      <View style={styles.recommendationHeader}>
        <Text style={styles.recommendationHeaderText}>Recommendation:</Text>
      </View>
      <Text style={styles.recommendationText}>{recommendation}</Text>
      {apiError && (
        <View style={styles.errorContainer}>
          <Text style={styles.errorText}>{apiError}</Text>
        </View>
      )}
      <View style={styles.responseTextsContainer}>
        {responseTexts.map((text, index) => (
          <Text key={index} style={styles.responseText}>{text}</Text>
        ))}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
  },
  header: {
    backgroundColor: '#f0f0f0',
    padding: 10,
    borderRadius: 5,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  headerText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#004d40',
  },
  input: {
    borderWidth: 1,
    borderColor: '#000',
    padding: 10,
    marginVertical: 10,
    height: 100,
    textAlignVertical: 'top',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 10,
  },
  emotionsHeader: {
    backgroundColor: '#e0f2f1',
    padding: 10,
    marginTop: 20,
  },
  emotionsHeaderText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#00695c',
  },
  table: {
    borderWidth: 1,
    borderColor: '#ddd',
    marginTop: 10,
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#f0f0f0',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  tableHeaderText: {
    flex: 1,
    fontWeight: 'bold',
    padding: 10,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
  },
  tableCell: {
    flex: 1,
    padding: 10,
  },
  recommendationHeader: {
    marginTop: 20,
  },
  recommendationHeaderText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  recommendationText: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 5,
    color: '#2e7d32',
  },
  errorContainer: {
    backgroundColor: '#ffcccb',
    padding: 10,
    marginTop: 10,
    borderRadius: 5,
  },
  errorText: {
    color: 'red',
    textAlign: 'center',
  },
  responseTextsContainer: {
    marginTop: 20,
  },
  responseText: {
    fontSize: 16,
    color: 'black',
    marginBottom: 10,
  },
});

export default App;
